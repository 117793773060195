import React from 'react'
import { Col, Row } from 'reactstrap'
import { BUSINESS_UNITS, Title, ISyllabusProgressProps } from '../../../common'
import { CandidateStats } from './candidate-stats'
import { StatusAndControlBlock } from './status-and-control-block'

export const CiSyllabusProgress: React.FC<ISyllabusProgressProps> = ({
  title,
  subTitle,
  syllabusId,
  centreId,
  totalCandidates,
  gradedCandidates,
  inProgress,
  user,
  syllabusTemplateFileName,
  closed,
  is,
  onUpdate,
  deadline,
}) => {
  return (
    <>
      <Row className="align-items-center justify-content-between mb-4">
        <Col>
          <Title title={title} subTitle={subTitle} />
        </Col>
        <Col xs="auto" className="text-right">
          <CandidateStats
            syllabusId={syllabusId}
            gradedCandidates={gradedCandidates}
            totalCandidates={totalCandidates}
          />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-start">
        <StatusAndControlBlock
          businessUnit={BUSINESS_UNITS.CambridgeInternational}
          title={title}
          subTitle={subTitle}
          syllabusId={syllabusId}
          centreId={centreId}
          totalCandidates={totalCandidates}
          gradedCandidates={gradedCandidates}
          inProgress={inProgress}
          user={user}
          syllabusTemplateFileName={syllabusTemplateFileName}
          closed={closed}
          is={is}
          onUpdate={onUpdate}
          deadline={deadline}
        />
      </Row>
    </>
  )
}
