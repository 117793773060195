import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faBookOpen,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { faShield } from '@fortawesome/pro-solid-svg-icons'
import getTextFromToken from '../../common/services/tokenised-text'
import {
  isOCR,
  isCambridgeInternational,
  useBackButtonUpdate,
} from '../../common'
import ReactGA from 'react-ga'

export const HelpAndContact: React.FC = (): JSX.Element => {
  const backButtonUpdate = useBackButtonUpdate()
  const history = useHistory()

  useEffect(() => {
    backButtonUpdate({
      returnUrl: '/',
      text: 'Home',
    })
    // if backButtonUpdate added to dependencies, causes perpetual re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location])

  return (
    <>
      <div className={`px-3 mb-5 bg-light py-45`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="h4 font-weight-bold text-primary">
                Help and Contact
              </div>
              {isCambridgeInternational() && (
                <>
                  <p>
                    We have created a range of support to help you use the
                    system, including a step-by-step 'How to guide', a video
                    tutorial and FAQs.
                  </p>
                  <p>
                    You can access this support on our public website using the
                    link below or by visiting{' '}
                    <ReactGA.OutboundLink
                      eventLabel={getTextFromToken('helpGuideUri')}
                      to={getTextFromToken('helpGuideUri')}
                      target="_blank"
                    >
                      https://www.cambridgeinternational.org/covid/november-2021-exam-series/school-assessed-grades/
                    </ReactGA.OutboundLink>
                  </p>
                </>
              )}
              {isOCR() && (
                <>
                  <div>
                    For support and guidance, please see the OCR website:
                  </div>
                </>
              )}
              <ListGroup className="mt-4">
                <ListGroupItem className="border-0 bg-transparent px-0 py-1 font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={getTextFromToken('resourcesFaqUri')}
                    to={getTextFromToken('resourcesFaqUri')}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      fixedWidth
                      className="mr-3"
                    />{' '}
                    <u>
                      {isCambridgeInternational() &&
                        'See support resources and FAQs'}
                      {isOCR() && 'Visit OCR website'}
                    </u>
                  </ReactGA.OutboundLink>{' '}
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent px-0 py-1  font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={getTextFromToken('privacyPolicyUri')}
                    to={getTextFromToken('privacyPolicyUri')}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faShield}
                      fixedWidth
                      className="mr-3"
                    />
                    Privacy Policy
                  </ReactGA.OutboundLink>
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent px-0 py-1  font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={getTextFromToken('tandcUri')}
                    to={getTextFromToken('tandcUri')}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faBookOpen}
                      fixedWidth
                      className="mr-3"
                    />
                    Terms and Conditions
                  </ReactGA.OutboundLink>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        {isOCR() && (
          <p className="font-weight-bold mb-3">
            If you require any assistance please contact us here.
          </p>
        )}
        {isCambridgeInternational() && (
          <>
            <p className="font-weight-bold mb-3">
              If you need to contact our Customer Services team,
            </p>
            <p>
              you can visit{' '}
              <ReactGA.OutboundLink
                eventLabel="https://www.cambridgeinternational.org/help"
                to="https://www.cambridgeinternational.org/help"
                target="_blank"
              >
                www.cambridgeinternational.org/help
              </ReactGA.OutboundLink>{' '}
              or use the contact details below.{' '}
            </p>
          </>
        )}

        <Row>
          <Col xs="auto">
            <Button
              tag="a"
              href={`mailto:${getTextFromToken('supportMail')}`}
              color="lightButNotTooLight font-weight-bold text-secondary"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <div>{getTextFromToken('supportMail')}</div>
            </Button>
          </Col>
          <Col xs="auto">
            <Button color="lightButNotTooLight font-weight-bold text-secondary">
              <FontAwesomeIcon icon={faPhone} />
              <div>{getTextFromToken('supportTel')}</div>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
