import React, { useMemo } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { AxiosResponse } from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import {
  faArrowToBottom,
  faArrowToTop,
  faClock,
} from '@fortawesome/pro-solid-svg-icons'

import {
  DataSource,
  getSubTitleBySyllabus,
  Title,
  isOCR,
  CentreSyllabus,
  SyllabusCandidate,
} from '../../../../../common'
import { adjustTimestampToUtc } from '../../../../candidates-list/constants'
import getTextFromToken from '../../../../../common/services/tokenised-text'
import { ApprovalButton } from '../../approval-button/approval-button'
import { UploadTemplateButton } from '../../upload-template-button/upload-template-button'
import { DownloadTemplateButton } from '../../../../download-template-button/download-template-button'

interface ISubjectPageHeaderNotOnlyView {
  syllabus: CentreSyllabus
  showButtons: boolean
  approvalDisabled: boolean
  viewOnly: boolean
  onUploadComplete: (uploadedWithErr: boolean) => void
  setSyllabusCallback: (syllabus: CentreSyllabus | undefined) => void
}

export const SubjectPageHeaderEditMode: React.FC<ISubjectPageHeaderNotOnlyView> = ({
  syllabus,
  showButtons,
  approvalDisabled,
  viewOnly,
  onUploadComplete,
  setSyllabusCallback,
}) => {
  const isOcrAndCamsDataSource = useMemo(
    () => isOCR() && syllabus?.dataSource === DataSource.CAMS,
    [syllabus]
  )

  return (
    <>
      <Row>
        <Col className="my-3">
          <Title
            title={syllabus?.syllabusName || ''}
            subTitle={getSubTitleBySyllabus(syllabus)}
            ancillery={`${syllabus?.totalCandidates} candidates`}
          />
        </Col>
        {showButtons && (
          <Col
            xs={24}
            md="auto"
            className="text-center text-md-right mt-0 mt-md-3 mb-4"
          >
            <DownloadTemplateButton syllabus={syllabus} />
            <UploadTemplateButton
              syllabus={syllabus}
              onUploadComplete={onUploadComplete}
            />
            <ApprovalButton
              syllabus={syllabus}
              approvalDisabled={approvalDisabled}
              setSyllabusCallback={setSyllabusCallback}
            />
          </Col>
        )}
      </Row>
      <Row className="mb-4">
        <Col lg={11} className="text-dark">
          {approvalDisabled ? (
            <div>
              {isOcrAndCamsDataSource ? (
                <div>
                  Enter your candidates’ grades and pathways (if applicable).
                  Your data is saved automatically.
                </div>
              ) : (
                <div>{getTextFromToken('enterGrades')}</div>
              )}
              <div>Send for approval once all data is correct. You can:</div>
            </div>
          ) : (
            <div>
              All data has been entered / uploaded correctly, checked and
              automatically saved.{' '}
              <strong>You can send for approval now.</strong>
            </div>
          )}
        </Col>
      </Row>

      <Row>
        {approvalDisabled && (
          <Col className="text-dark">
            <ul className="list-inline font-weight-bold mb-0">
              <li className="list-inline-item mr-3">
                <span className="mr-2">&bull;</span> Download and upload
                templates
              </li>
              <li className="list-inline-item mr-3">
                <span className="mr-2">&bull;</span> Enter or edit grades on
                screen
              </li>
              <li className="list-inline-item mr-3">
                <span className="mr-2">&bull;</span> Check for errors
              </li>
            </ul>
          </Col>
        )}
        {!viewOnly && (
          <Col
            xs={approvalDisabled ? 'auto' : '24'}
            className="text-secondary font-weight-bold text-right"
          >
            <FontAwesomeIcon icon={faClock} className="mr-2" />

            {`Deadline is ${format(
              adjustTimestampToUtc(syllabus.deadline),
              isOCR() ? 'd MMMM yyyy' : 'do MMMM yyyy'
            )}`}
          </Col>
        )}
      </Row>
    </>
  )
}
