import { BUSINESS_UNITS, getBusinessUnit } from './util'

const DICTIONARY_CI: { [key: string]: string } = {
  title:
    'Cambridge Assessment International Education | Grade Submission System',
  favicon: 'favicon-ci.ico',
  grades: 'Predicted Grades',
  assessedGrades: 'School-assessed grades',
  syllabus: 'syllabus',
  syllabuses: 'syllabuses',
  supportMail: 'info@cambridgeinternational.org',
  supportTel: 'Phone (+44 1223 553 554)',
  helpGuideUri:
    'https://www.cambridgeinternational.org/covid/november-2021-exam-series/school-assessed-grades/',
  resourcesFaqUri:
    'https://www.cambridgeinternational.org/covid/june-2021-exam-series/school-assessed-grades/',
  privacyPolicyUri:
    'https://www.cambridgeinternational.org/privacy-and-legal/data-protection-and-privacy/',
  tandcUri:
    'https://www.cambridgeinternational.org/privacy-and-legal/terms-and-conditions/',
  filterText: 'Filter by grade',
  uploadMessage:
    'Your template must be in CSV format. Uploading a template will replace any information that’s already been entered for this syllabus.',
  uploadStarted:
    'We are uploading your template and checking for viruses. This may take a few moments. Once the upload is complete all existing data for this syllabus will be replaced.',
  uploadFinished:
    'The information for this syllabus has now been updated, replacing any previously entered information. Please check your data before sending for approval.',
  uploadFailed:
    'You will need to check and amend the errors below before sending for approval.',
  approvalNoHoc:
    'Please contact your Centre Administrator. Currently there is no Head of Centre set up. Once a Head of Centre is set up, you will be able to send your syllabus for approval.',
  enterGrades:
    "Enter your candidates' grades. Your data is saved automatically.",
  downloadModalBody:
    'This template is a CSV file that has been pre-populated with candidate names and numbers. It will also include any data you have already added in the system.',
  approvalSend:
    'You are about to send your school-assessed grades for this subject to your Head of Centre to approve.',
  approvalConfirmation:
    'Please wait while your Head of Centre reviews and approves your school-assessed grades for this subject. You can still view your data from the Dashboard. ',
  approvalConfirmationHeader: 'Your data has been sent for approval',
}

const DICTIONARY_OCR: { [key: string]: string } = {
  title: 'OCR | Grade Submission System',
  favicon: 'favicon-ocr.ico',
  grades: 'Teacher Assessment Grades',
  assessedGrades: 'Teacher assessed grades',
  syllabus: 'qualification',
  syllabuses: 'qualifications',
  supportMail: 'support@ocr.org.uk',
  supportTel: 'Phone (01223 553 998)',
  helpGuideUri:
    'https://www.ocr.org.uk/everything-you-need-to-know-for-summer-2021/grade-submission-system/',
  resourcesFaqUri:
    'https://www.ocr.org.uk/everything-you-need-to-know-for-summer-2021/grade-submission-system/',
  privacyPolicyUri:
    'https://www.ocr.org.uk/about/our-policies/website-policies/privacy-policy/',
  tandcUri:
    'https://www.ocr.org.uk/about/our-policies/website-policies/terms-and-conditions/',
  filterText: 'Filter by grade',
  uploadMessage:
    'Your template must be in CSV format. Uploading a template will overwrite any teacher assessed grades currently in the system.',
  uploadStarted:
    'We are uploading your template and checking for viruses. This may take a few moments. Once the upload is complete all existing data will be replaced.',
  uploadFinished:
    'The information for this qualification has now been updated, replacing any previously entered information. Please check your data and complete it ready for sign off.',
  uploadFailed:
    'All valid data will be imported, but the errors below will not be imported in to the system.',
  approvalNoHoc:
    'Please contact your Centre Admin. Currently there is no Head of Centre set up. Once a Head of Centre is set up, you will be able to send your qualification for approval.',
  enterGrades:
    "Enter your candidates' grades. Your data is saved automatically.",
  downloadModalBody:
    'The template is a CSV file containing all the entry information for this qualification. It will also include any teacher assessed grades you’ve already entered into the system.',
  approvalSend:
    'You are about to send your teacher assessed grades for this qualification to your Head of Centre to approve.',
  approvalConfirmation:
    'Please wait while your Head of Centre reviews and approves your teacher assessed grades for this qualification. You can still view your data from the Dashboard. ',
  approvalConfirmationHeader:
    'Your data has been sent for approval to the Head of Centre',
}

const dictionaryMap = {
  [BUSINESS_UNITS.CambridgeInternational]: DICTIONARY_CI,
  [BUSINESS_UNITS.OCR]: DICTIONARY_OCR,
}

const getTextFromToken = (key: string): string =>
  (dictionaryMap[getBusinessUnit()] || {})[key] || ''

export default getTextFromToken
